<template>
    <div class="survey" v-wechat-title="$route.meta.title=detail.survey_name" :img-set="detail.survey_image">
        <!-- <div class="survey-name">{{detail.survey_name}}</div> -->
        <div style="margin-top: 10px;" v-if="detail.survey_image">
            <van-image v-if="detail.survey_image" radius="3.5" :src="detail.survey_image" />
        </div>
        <div class="ql-editor" v-html="detail.survey_descr" v-if="detail.survey_descr" style="padding-left:0px;padding-right:0px;"></div>
        <div style="margin-top: 10px;">
            <van-form @submit="save" :colon='true'>
                <div v-for="(item,index) in questions" :key='item.survey_question_id'>
                    <van-field
                        v-if="item.survey_question_type == 'text' || item.survey_question_type == 'email'"
                        v-model="survey[item.valueName]"
                        :name="item.survey_name_id"
                        :label="item.label"
                        placeholder="请输入"
                        @input="handleInput"
                        class="survey-van-field-boeder"
                        :required='item.survey_question_can_skip == 1'
                        :rules="[{ required: item.survey_question_can_skip == 1, message: '请输入' }]"
                    />

                    <van-field
                        v-if="item.survey_question_type == 'verify_code'"
                        v-model="survey[item.valueName]"
                        :name="item.survey_name_id"
                        :label="item.label"
                        placeholder="请输入"
                        @input="handleInput"
                        class="survey-van-field-boeder"
                        :required='item.survey_question_can_skip == 1'
                        :rules="[{ required: item.survey_question_can_skip == 1, message: '请输入' }]"
                        >
                        <template #button>
                            <div v-if="!showTime" @click="sendCode" class="survey-btn">{{codeName}}</div>
                            <div v-else class="survey-btn">
                                <van-count-down @finish='handleTimeFinish' :time="CountDownTime" format="ss 秒"/>
                            </div>
                        </template>
                    </van-field>

                    <van-field
                        v-if="item.survey_question_type == 'mobile'"
                        v-model="survey[item.valueName]"
                        type="tel"
                        :name="item.survey_name_id"
                        :label="item.label"
                        placeholder="请输入"
                        @input="handleInput"
                        class="survey-van-field-boeder"
                        :required='item.survey_question_can_skip == 1'
                        :rules="[{ validator, required: item.survey_question_can_skip == 1, message: '请输入正确的手机号' }]"
                    />

                    <van-field
                        v-if="item.survey_question_type == 'number'"
                        v-model="survey[item.valueName]"
                        type="number"
                        :name="item.survey_name_id"
                        :label="item.label"
                        placeholder="请输入"
                        @input="handleInput"
                        class="survey-van-field-boeder"
                        :required='item.survey_question_can_skip == 1'
                        :rules="[{ required: item.survey_question_can_skip == 1, message: '请输入' }]"
                    />

                    <van-field
                        v-if="item.survey_question_type == 'checkbox'"
                        :name="item.survey_name_id"
                        :label="item.label"
                        class="survey-van-radio-box"
                        :required='item.survey_question_can_skip == 1'
                        :rules="[{ required: item.survey_question_can_skip == 1, message: '请选择' }]">
                        <template #input>
                            <van-checkbox-group checked-color="#00DE93" v-model="survey.checkedList" direction="horizontal">
                                <van-checkbox @click="handleCheckbox(it)" :class="it.checkB?'survey-van-radio-boederCheck':'survey-van-radio-boeder'"  v-for="it in item.options" :key='it.survey_answer_id' :name="it.survey_answer_id" shape="square">{{it.survey_answer_name}}</van-checkbox>
                            </van-checkbox-group>
                        </template>
                    </van-field>


                    <van-field
                        v-if="item.survey_question_type == 'radio' || item.survey_question_type == 'gender'"
                        :name="item.survey_name_id"
                        :label="item.label"
                        class="survey-van-radio-box"
                        :required='item.survey_question_can_skip == 1'
                        :rules="[{ required: item.survey_question_can_skip == 1, message: '请选择' }]">
                        <template #input>
                            <van-radio-group v-model="survey[item.valueName]" @change="handleInput" direction="horizontal">
                                <van-radio :class="survey[item.valueName] == it.survey_answer_id?'survey-van-radio-boederCheck':'survey-van-radio-boeder'" v-for="it in item.options" @click="handleInput" :key='it.survey_answer_id' :name="it.survey_answer_id" >{{it.survey_answer_name}}</van-radio>
                            </van-radio-group>
                        </template>
                    </van-field>


                    <van-field
                        v-if="item.survey_question_type == 'textarea'"
                        v-model="survey[item.valueName]"
                        rows="2"
                        autosize
                        :label="item.label"
                        :name="item.survey_name_id"
                        type="textarea"
                        maxlength="1000"
                        placeholder="请输入留言"
                        show-word-limit
                        @input="handleInput"
                        class="survey-van-textarea-boeder"
                        :required='item.survey_question_can_skip == 1'
                        :rules="[{ required: item.survey_question_can_skip == 1, message: '请输入' }]"
                    />


                    <van-field
                        v-if="item.survey_question_type == 'dropdown'"
                        readonly
                        clickable
                        :name="item.survey_name_id"
                        :label="item.label"
                        :value="survey[item.valueName]"
                        placeholder="点击选择"
                        @click="showDropdown(index)"
                        class="survey-van-field-boeder"
                        :required='item.survey_question_can_skip == 1'
                        :rules="[{ required: item.survey_question_can_skip == 1, message: '请选择' }]"
                    />

                    <van-field
                        v-if="item.survey_question_type == 'studio'"
                        readonly
                        clickable
                        :name="item.survey_name_id"
                        :label="item.label"
                        :value="survey[item.valueName]"
                        placeholder="点击选择"
                        @click="showStudio = true"
                        class="survey-van-field-boeder"
                        :required='item.survey_question_can_skip == 1'
                        :rules="[{ required: item.survey_question_can_skip == 1, message: '请选择' }]"
                    />

                    <van-field
                        v-if="item.survey_question_type == 'teacher'"
                        readonly
                        clickable
                        :name="item.survey_name_id"
                        :label="item.label"
                        :value="survey[item.valueName]"
                        placeholder="点击选择"
                        @click="showTeacher = true"
                        class="survey-van-field-boeder"
                        :required='item.survey_question_can_skip == 1'
                        :rules="[{ required: item.survey_question_can_skip == 1, message: '请选择' }]"
                    />

                    <van-field
                        v-if="item.survey_question_type == 'map_address'"
                        readonly
                        clickable
                        :name="item.survey_name_id"
                        :label="item.label"
                        :value="survey[item.valueName]"
                        placeholder="点击选择"
                        @click="showMapAddress = true"
                        class="survey-van-field-boeder"
                        :required='item.survey_question_can_skip == 1'
                        :rules="[{ required: item.survey_question_can_skip == 1, message: '请选择' }]"
                    />

                    <van-field
                        v-if="item.survey_question_type == 'course_consultant'"
                        readonly
                        clickable
                        :name="item.survey_name_id"
                        :label="item.label"
                        :value="survey[item.valueName]"
                        placeholder="点击选择"
                        @click="showCourseConsultant = true"
                        class="survey-van-field-boeder"
                        :required='item.survey_question_can_skip == 1'
                        :rules="[{ required: item.survey_question_can_skip == 1, message: '请选择' }]"
                    />
                    

                    <van-field
                        v-if="item.survey_question_type == 'date'"
                        readonly
                        clickable
                        :value="survey[item.valueName]"
                        :name="item.survey_name_id"
                        :label="item.label"
                        placeholder="点击选择"
                        @click="showPickers = true"
                        class="survey-van-field-boeder"
                        :required='item.survey_question_can_skip == 1'
                        :rules="[{ required: item.survey_question_can_skip == 1, message: '请选择' }]"
                    />


                    <van-field
                        v-if="item.survey_question_type == 'datetime'"
                        readonly
                        clickable
                        :value="survey[item.valueName]"
                        :name="item.survey_name_id"
                        :label="item.label"
                        placeholder="点击选择"
                        @click="showPickert = true"
                        class="survey-van-field-boeder"
                        :required='item.survey_question_can_skip == 1'
                        :rules="[{ required: item.survey_question_can_skip == 1, message: '请选择' }]"
                    />


                    <van-field 
                        v-if="item.survey_question_type == 'image'"
                        :rules="[{ required: item.survey_question_can_skip == 1, message: '请选择' }]"
                        :name="item.survey_name_id"
                        :label="item.label"
                        :required='item.survey_question_can_skip == 1'
                        >
                        <template #input>
                            <van-uploader :after-read="e=>afterRead(e,item.imgList)" :before-delete='(e,b)=>beforeDelete(e,b,item.imgList)' multiple :max-count="9" v-model="survey[item.valueName]" />
                        </template>
                    </van-field>
                    
                    <div class="signature-box" v-if="item.survey_question_type == 'signature'" >
                        <div class="signature-box-title"><span v-if="item.survey_question_can_skip == 1">*</span>{{item.label}}:</div>
                        <VueSignaturePad 
                            id="signature"
                            height="200px" 
                            :options="SignatureOptions"
                            ref="signaturePad" />
                        <van-row gutter="20">
                            <van-col span="24"><div class="signature-btn" @click="undo" type="default">清除</div></van-col>
                            <!-- <van-col span="12"><div class="signature-btn" @click="save" type="default">保存</div></van-col> -->
                        </van-row>
                    </div>
                    
                    <div class="location" v-if="item.survey_question_type == 'location'" >
                        <div class="location-title"><span v-if="item.survey_question_can_skip == 1">*</span>{{item.label}}:</div>
                        <div v-if='!isMap' @click="getLocation" class="location-wrap">
                            <van-icon color="#00DE93" size="25" name="location-o" />
                            <span style="color:#00DE93">点击获取地理位置</span>
                        </div>
                        <div class="location-map" v-if='isMap' >
                            <div class="map-wrap" :id="`map`"></div>
                            <div @click="getLocation" class="location-icon"><van-icon size="20" name="aim" /></div>
                        </div>
                        <div v-if='isMap' class="location-address">{{newValue}}</div>
                    </div>

                    <van-popup v-model="showStudio" position="bottom" v-if="item.survey_question_type == 'studio'">
                        <van-search left-icon="false" placeholder="请输入搜索名称" shape="round" background="#4fc08d" v-model="optSearch[item.survey_name_id]" @input="onSearch2(item.survey_name_id)" @search="onSearch2(item.survey_name_id)" />
                        <van-picker
                            show-toolbar
                            value-key='label'
                            :columns="optRet[item.survey_name_id]"
                            @confirm="e=>studioConfirm(e,item.valueName,item.select)"
                            @cancel="showStudio = false"
                        />
                    </van-popup>

                    <van-popup v-model="showTeacher" position="bottom" v-if="item.survey_question_type == 'teacher'">
                        <van-search left-icon="false" placeholder="请输入搜索名称" shape="round" background="#4fc08d" v-model="optSearch[item.survey_name_id]" @input="onSearch2(item.survey_name_id)" @search="onSearch2(item.survey_name_id)" />
                        <van-picker
                            show-toolbar
                            value-key='label'
                            :columns="optRet[item.survey_name_id]"
                            @confirm="e=>teacherConfirm(e,item.valueName,item.select)"
                            @cancel="showTeacher = false"
                        />
                    </van-popup>

                    <van-popup v-model="showMapAddress" position="bottom" v-if="item.survey_question_type == 'map_address'">
                        <van-area
                            :area-list="areaList"
                            @confirm="e=>mapAddressConfirm(e,item.valueName,item.select)"
                            @cancel="showMapAddress = false"
                        />
                    </van-popup>

                    <van-popup v-model="showCourseConsultant" position="bottom" v-if="item.survey_question_type == 'course_consultant'">
                        <van-search left-icon="false" placeholder="请输入搜索名称" shape="round" background="#4fc08d" v-model="optSearch[item.survey_name_id]" @input="onSearch2(item.survey_name_id)" @search="onSearch2(item.survey_name_id)" />
                        <van-picker
                            show-toolbar
                            value-key='label'
                            :columns="optRet[item.survey_name_id]"
                            @confirm="e=>courseConsultantConfirm(e,item.valueName,item.select)"
                            @cancel="showCourseConsultant = false"
                        />
                    </van-popup>

                    <van-popup v-model="showPicker" position="bottom" v-if="item.survey_question_type == 'dropdown' && activeIndex == index">
                        <van-picker
                            show-toolbar
                            value-key='label'
                            :columns="item[item.optionsName]"
                            @confirm="e=>onConfirm(e,item.valueName,item.select,index)"
                            @cancel="showPicker = false"
                        />
                    </van-popup>
                    <van-popup v-model="showPickers" position="bottom" v-if="item.survey_question_type == 'date'">
                        <van-datetime-picker
                            type="date"
                            :min-date="minDate"
                            :max-date="maxDate"
                            v-model="survey[item.date]"
                            @confirm="e=>onConfirmDate(e,item.valueName)"
                            @cancel="showPickers = false"
                        />
                    </van-popup>
                    <van-popup v-model="showPickert" position="bottom" v-if="item.survey_question_type == 'datetime'">
                       <van-datetime-picker
                            type="datetime"
                            :min-date="minDate"
                            :max-date="maxDate"
                            v-model="survey[item.date]"
                            @confirm="e=>onConfirmDateTime(e,item.valueName)"
                            @cancel="showPickert = false"
                        />
                    </van-popup>
                </div>
                <div v-if="!recruit_id" style="padding: 16px;background:#fff">
                    <van-button v-if="questions.length!=0" round block type="info" native-type="submit">
                    提交
                    </van-button>
                </div>
                <div v-if="recruit_id" style="padding: 0 16px;background:#fff">
                    <van-checkbox v-model="isCheck" checked-color="#07c160">
                        <span>我已阅读并同意</span>
                        <span @click.stop="toAgreement" style="color:#00DE93">《报名须知》</span>
                    </van-checkbox>
                </div>
                <div v-if="recruit_id" style="padding: 16px;background:#fff">
                    <van-button :disabled="!isCheck" v-if="questions.length!=0" round block type="info" native-type="submit">
                        立即支付
                    </van-button>
                </div>
            </van-form>
        </div>
        <copyright v-if="!loading" style="margin-top:10px" />
        <loadings :loading='loading' />

        <div 
            class="float-ball"
            ref="ball"
            v-if="detail.survey_qywx_id"
            @touchstart.stop='handleTouchstart'
            @touchmove.stop='handleTouchmove'
            @touchend.stop='handleTouchend'
            @click.stop="showCard"
            :style="{top:`${moveTop}px`,left:`${moveLeft}px`}"
            >
            <van-icon color="#00cca2" name="https://i01.wedots.cn/2020/11/23/e64770ac6bac05626b4f59b6a03e2ff8.jpeg" />
        </div>

        <!-- <div style="height:300px" class="map-wrap" :id="`map`"></div> -->
        <van-popup v-model="isAgreement" closeable close-icon="close" round position="bottom" :style="{ height: '500px',overflow: 'auto'}">
            <div class="agreement ql-editor" v-html="agreementHtml"></div>
        </van-popup>

    </div>
</template>

<script>
    import 'quill/dist/quill.core.css'
    import 'quill/dist/quill.snow.css'
    import 'quill/dist/quill.bubble.css'

    import upload from '@/utils/upload'
    import areaList from '@/common/area'
    import Loadings from '@/components/loading'
    import dayjs from 'dayjs'
    import resApi from '@/api'
    import { wxPay } from '@/common/wx'
    import { isPhone } from '@/common/validate'
    import Copyright from '@/components/Copyright'
    import { lazyAMapApiLoaderInstance } from 'vue-amap'
    import { Search, Checkbox, CheckboxGroup, Form, Field, RadioGroup, Radio, Picker, Popup, DatetimePicker, Uploader ,Overlay,Area,CountDown} from 'vant'

    export default {
        name:"survey",
        components: {
            [Field.name]: Field,
            [Form.name]: Form,
            [Checkbox.name]: Checkbox,
            [CheckboxGroup.name]: CheckboxGroup,
            [Radio.name]: Radio,
            [Search.name]:Search,
            [RadioGroup.name]: RadioGroup,
            [Picker.name]: Picker,
            [Popup.name]: Popup,
            [DatetimePicker.name]: DatetimePicker,
            [Uploader.name]: Uploader,
            [Overlay.name]: Overlay,
            [Area.name]: Area,
            [CountDown.name]: CountDown,
            loadings:Loadings,
            Copyright,
        },
        data() {
            return {
                detail: {},
                questions:[],
                showTime:false,
                showPicker:false,
                showPickers:false,
                showPickert:false,
                showStudio:false,
                showTeacher:false,
                showMapAddress:false,
                showCourseConsultant:false,
                survey:{},
                optRet:{},
                optSearch:{},
                optValues:{},
                SignatureOptions:{
                    penColor: "#000"
                },
                survey_id:'',
                signaturePad:'',
                survey_msg:{},
                recruit_id:'',
                minDate: new Date(1970, 1, 1),
                maxDate: new Date(9999, 12, 30),
                activeIndex:-1,
                moveTop:415,
                moveLeft:317,
                newValue:'',
                isMap:false,
                loading:false,
                areaList: areaList,
                CountDownTime:59000,
                codeName:'发送验证码',
                mobile:'',
                isCheck:false,
                isAgreement:false,
                agreementHtml: '',
                zhuqian:0,
                coupon_type:''
            }
        },
        created () {
            let res = this.$route.query
            this.survey_id = res.id
            this.survey_msg = res
            if(res.recruit_ids){
                this.zhuqian = res.zhuqian
                this.coupon_type = res.coupon_type
                this.recruit_id = JSON.parse(res.recruit_ids)
                this.getAgreement()
            }
            this.getDetail()
        },
        methods: {
            onQywxClick(){
                // this.$api.click_index({event_id:this.meet_id,event_type:'survey_qywx_click'}).then(res=>{})
            },
            async getDetail() {
                this.loading = true
                let that = this
                await this.$api.survey_detail({ survey_id: this.survey_id})
                    .then(res => {
                        this.detail = res.data.survey
                        this.mobile = res.data.mobile
                        res.data.survey.questions.forEach((item,index)=>{
                            let valueName = item.survey_question_type + index
                            let img = 'imgList' + index
                            let select = 'selectValue' + index
                            let date = 'dateValue' + index
                            let optionsList = 'options' + index
                            item.valueName = valueName
                            item.survey_name_id = item.survey_question_id.toString()
                            if(item.survey_question_type == 'checkbox' || item.survey_question_type == 'image' ){
                                item.imgList = img
                                that.survey[valueName] = []
                                that.survey[img] = []
                                that.survey.checkedList = []
                                if(item.survey_question_type == 'checkbox'){
                                    item.options.forEach((it,j)=>{
                                        if(item.survey_user_answer_value && item.survey_user_answer_value[j] && item.survey_user_answer_value[j] == 1){
                                            it.checkB = true
                                            that.survey.checkedList.push(it.value)
                                        }else{
                                            it.checkB = false
                                        }
                                    })
                                }
                            }else if(item.survey_question_type == 'dropdown' || item.survey_question_type == 'teacher' || item.survey_question_type == 'course_consultant' || item.survey_question_type == 'map_address'){
                                item.select = select
                                item[optionsList] = item.options
                                item.optionsName = optionsList
                                that.survey[select] = ''
                                that.survey[valueName] = ''
                                if(item.survey_user_answer_value){
                                    item.options.forEach(it=>{
                                        if(it.value == item.survey_user_answer_value){
                                            that.survey[select] = item.survey_user_answer_value
                                            that.survey[valueName] = it.label
                                        }
                                    })
                                }

                                that.optValues[item.survey_name_id] = item.options
                                this.optRet[item.survey_name_id] = item.options

                            }else if(item.survey_question_type == 'studio'){
                                item.select = select
                                item[optionsList] = item.options
                                item.optionsName = optionsList
                                that.survey[select] = ''
                                that.survey[valueName] = ''
                                if(item.survey_user_answer_value){
                                    item.options.forEach(it=>{
                                        if(it.label == item.survey_user_answer_value){
                                            that.survey[select] = it.value
                                            that.survey[valueName] = it.label
                                        }
                                    })
                                }

                                that.optValues[item.survey_name_id] = item.options
                                this.optRet[item.survey_name_id] = item.options
                                
                            }else if(item.survey_question_type == 'date'){
                                item.date = date
                                that.survey[date] = dayjs(item.survey_user_answer_value).$d || new Date()
                                that.survey[valueName] = dayjs(item.survey_user_answer_value).format('YYYY-MM-DD') || dayjs().format('YYYY-MM-DD')
                            }else if(item.survey_question_type == 'datetime'){
                                item.date = date
                                that.survey[date] = dayjs(item.survey_user_answer_value).$d || new Date()
                                that.survey[valueName] = dayjs(item.survey_user_answer_value).format('YYYY-MM-DD HH:mm') || dayjs().format('YYYY-MM-DD HH:mm')
                            }else if(item.survey_question_type == 'mobile'){
                                that.survey[valueName] = res.data.mobile
                            }else{
                                that.survey[valueName] = item.survey_user_answer_text || item.survey_user_answer_value || ''
                            }

                            item.survey_name_id = item.survey_question_id.toString()
                            if(item.survey_question_descr){
                                item.label = `${item.survey_question_name}(${item.survey_question_descr})`
                            }else{
                                item.label = `${item.survey_question_name}`
                            }
                        })
                        this.questions = res.data.survey.questions
                        this.loading = false
                    })
                    .catch(err => {
                        this.loading = false
                        console.error(err)
                    })
            },
            async getAgreement(){
                await this.$api.premium_agreement()
                .then(res=>{
                    this.agreementHtml = res.data.agreement
                })
            },
            showCard(){
              this.$api.click_index({event_id:this.detail.survey_id,event_type:'survey'}).then(res=>{})
              this.$router.push({path:'/namecard',query:{qywx_id:this.detail.survey_qywx_id}})  
            },
            showDropdown(index){
                this.activeIndex = index
                this.showPicker = true
            },
            onSubmit(value){
                let SurveyUserAnswer = {}
                try{
                    this.questions.forEach(item=>{
                        if(item.survey_question_type == 'image'){
                            SurveyUserAnswer[item.survey_name_id] = {survey_user_answer_value:this.survey[item.imgList]}
                        }else if(item.survey_question_type == 'signature'){
                            if(item.survey_question_can_skip == 1 && !this.signaturePad){
                                this.$refs.signaturePad[0].openSignaturePad();
                                this.$toast({ message: '请签名~', duration: 1500 })
                                throw new Error()
                            }
                            if(this.signaturePad){
                                SurveyUserAnswer[item.survey_name_id] = {survey_user_answer_value:this.signaturePad}
                            }else{
                                SurveyUserAnswer[item.survey_name_id] = {survey_user_answer_value:''}
                            }
                        }else if(item.survey_question_type == 'dropdown'){
                            SurveyUserAnswer[item.survey_name_id] = {survey_user_answer_value:this.survey[item.select],survey_user_answer_text:this.survey[item.valueName]}
                        }else if(item.survey_question_type == 'checkbox'){
                            let arr = {}
                            item.options.forEach(it=>{
                                value[item.survey_name_id].forEach(i=>{
                                    if(it.value  == i){
                                        it.ckeck = 1
                                    }
                                })
                            })
                            item.options.forEach(it=>{
                                if(it.ckeck){
                                    arr[it.value] = {survey_user_answer_value:'1',survey_user_answer_text:it.label}
                                }else{
                                    arr[it.value] = {survey_user_answer_value:'0',survey_user_answer_text:it.label}
                                }
                            })
                            SurveyUserAnswer[item.survey_name_id] = arr
                        }else if(item.survey_question_type == 'radio' || item.survey_question_type == 'gender'){
                            let arr = ''
                            item.options.forEach(it=>{
                                if(it.value == this.survey[item.valueName]){
                                    arr = it.label
                                }
                            })
                            SurveyUserAnswer[item.survey_name_id] = {survey_user_answer_value:value[item.survey_name_id],survey_user_answer_text:arr}
                        }else if(item.survey_question_type == 'studio' || item.survey_question_type == 'teacher' || item.survey_question_type == 'course_consultant'){
                            SurveyUserAnswer[item.survey_name_id] = {survey_user_answer_value:this.survey[item.valueName]}
                        }else if(item.survey_question_type == 'date' || item.survey_question_type == 'datetime'){
                            SurveyUserAnswer[item.survey_name_id] = {survey_user_answer_value:value[item.survey_name_id]}
                        }else if(item.survey_question_type == 'location'){
                            if(item.survey_question_can_skip == 1 && !this.newValue){
                                this.$toast({ message: '请获取当前地址~', duration: 1500 })
                                throw new Error()
                            }
                            SurveyUserAnswer[item.survey_name_id] = {survey_user_answer_text:this.newValue}
                        }else if(item.survey_question_type == 'map_address'){
                            SurveyUserAnswer[item.survey_name_id] = {survey_user_answer_value:this.survey[item.valueName]}
                            // console.log(this.survey[item.valueName],this.survey[item.select],item.valueName,item.select)

                        }else if( item.survey_question_type == 'number'){
                            SurveyUserAnswer[item.survey_name_id] = {survey_user_answer_value:value[item.survey_name_id]}
                        }else if(item.survey_question_type == 'email'){
                            SurveyUserAnswer[item.survey_name_id] = {survey_user_answer_value:value[item.survey_name_id]}
                        }else if(item.survey_question_type == 'mobile'){
                            SurveyUserAnswer[item.survey_name_id] = {survey_user_answer_value:value[item.survey_name_id] || this.mobile}
                        }else{
                            SurveyUserAnswer[item.survey_name_id] = {survey_user_answer_text:value[item.survey_name_id]}
                        }
                    })
                } catch (e){
                    this.loading = false
                    return false
                }
                let that = this
                this.$api.survey_submit({ survey_id: this.survey_id,SurveyUserAnswer:SurveyUserAnswer})
                    .then(res => {
                        this.loading = false
                        if(this.zhuqian){
                            // this.paymentRecruit()
                            this.$toast({ message: '提交成功~', duration: 1500 })
                            this.$router.push({path:'/paymentCenter',query:{zhuqian:this.zhuqian,recruit_ids:JSON.stringify(this.recruit_id),coupon_type:this.coupon_type}}) 
                        }else{
                            this.$toast({ message: '提交成功~', duration: 1500 })
                            // this.$router.push({path:'/submitSuccess',query:{zhuqian:this.zhuqian}})
                            if(this.detail.survey_qywx_id){
                                this.$router.push({path:'/namecard',query:{qywx_id:this.detail.survey_qywx_id}})  
                            }else{
                                this.$router.push({path:'/submitSuccess',query:{zhuqian:this.zhuqian}})
                            }
                        }
                    })
                    .catch(err => {
                        this.loading = false
                        console.error(err)
                    })
            },
            validator(val) {
                return isPhone(val)
            },
            onSearch2(name) {
                if(name){
                    this.optRet[name] = []
                    this.optValues[name].forEach((item) => {
                        if (item.label.toLowerCase().indexOf(this.optSearch[name].toLowerCase()) > -1) {
                            this.optRet[name].push(item)
                        }
                    })
                }else{
                    this.optRet[name] = this.optValues[name];
                }
            },
            onConfirm(value,name,select,index) {
                this.survey[name] = value.label;
                this.survey[select] = value.value;
                this.showPicker = false
            },
            studioConfirm(value,name,select){
                this.survey[name] = value.label;
                this.survey[select] = value.value;
                this.showStudio = false;
            },
            teacherConfirm(value,name,select){
                this.survey[name] = value.label;
                this.survey[select] = value.value;
                this.showTeacher = false;
            },
            mapAddressConfirm(value,name,select){
                this.survey[name] = `${value[0].name}${value[1].name}${value[2].name}`;
                this.survey[select] = value;
                this.showMapAddress = false;
            },
            courseConsultantConfirm(value,name,select){
                this.survey[name] = value.label;
                this.survey[select] = value.value;
                this.showCourseConsultant = false;
            },
            onConfirmDate(time,name){
                this.survey[name] = dayjs(time).format('YYYY-MM-DD');
                this.showPickers = false;
            },
            onConfirmDateTime(time,name){
                this.survey[name] = dayjs(time).format('YYYY-MM-DD HH:mm');
                this.showPickert = false;
            },
            afterRead(file,name) {
                this.$forceUpdate();
                let that = this;
                if(!file.length){
                    upload({ file: file.file })
                    .then(res => {
                        console.log(res)
                        that.survey[name].push(res.fileName)
                    })
                    .catch(err => {
                        console.error(err)
                    })
                }else{
                    file.forEach(it=>{
                        upload({ file: it.file })
                            .then(res => {
                                that.survey[name].push(res.fileName)
                            })
                            .catch(err => {
                                console.error(err)
                            })
                    })
                }
            },
            beforeDelete(file,detail,name){
                this.survey[name].splice(detail.index,1)
                this.$forceUpdate();
                return true
            },
            undo() {
                this.$refs.signaturePad[0].openSignaturePad();
                this.$refs.signaturePad[0].clearSignature();
            },
            save(value) {
                this.loading = true
                let that = this
                if(this.$refs.signaturePad){
                    this.$refs.signaturePad[0].lockSignaturePad();
                    const { isEmpty, data } = this.$refs.signaturePad[0].saveSignature();
                    if(!isEmpty){
                    let file = this.base64toFile(data)
                    upload({ file: file})
                        .then(res => {
                            that.signaturePad = res.fileName
                            that.onSubmit(value)
                        })
                    }else{
                        this.onSubmit(value)
                    }
                }else{
                    this.onSubmit(value);
                }
            },
            handleTouchstart(e){
                this.moveTop = e.changedTouches[0].clientY - 20
                this.moveLeft = e.changedTouches[0].clientX - 20
            },
            handleTouchmove(e){
                e.preventDefault()
                this.moveTop = e.changedTouches[0].clientY - 20
                this.moveLeft = e.changedTouches[0].clientX - 20
            },
            handleTouchend(e){
                this.moveTop = e.changedTouches[0].clientY - 20 
                this.moveLeft = e.changedTouches[0].clientX - 20
            },
            base64toFile (dataurl, filename = 'file') {
                let arr = dataurl.split(',')
                let mime = arr[0].match(/:(.*?);/)[1]
                let suffix = mime.split('/')[1]
                let bstr = atob(arr[1])
                let n = bstr.length
                let u8arr = new Uint8Array(n)
                while (n--) {
                u8arr[n] = bstr.charCodeAt(n)
                }
                return new File([u8arr], `${filename}.${suffix}`, {
                type: mime
                })
            },
            handleInput(index){
                this.$forceUpdate();
            },
            toAgreement(){
                this.isAgreement = true
            },
            paymentRecruit(recruit_id, couponId = '') {
                this.$toast.loading({
                    mask: true,
                    message: '支付中...',
                    duration: 0
                })
                this.$api.payment_recruit({recruit_id:this.recruit_id}).then(res => {
                    const { appId, timeStamp, nonceStr, package: packageStr, signType, paySign } = res.data.jsApiParameters
                    wxPay( appId, timeStamp, nonceStr, packageStr, signType, paySign)
                    .then(res => {
                        this.$toast.success('支付成功')
                        this.$router.push({path:'/submitSuccess',query:{zhuqian:this.zhuqian}})
                    })
                    .catch(err => {
                        this.$toast.clear()
                        if(err == 9){
                            this.$toast.fail('取消支付')
                        }else{
                            this.$toast.fail('支付失败')
                        }
                        setTimeout(() => {
                            
                        }, 1500)
                        console.error(err)
                    })
                })
                .catch(err => {
                    console.error(err)
                })
            },
            handleCheckbox(e){
                if(e.checkB){
                    e.checkB = false
                }else{
                    e.checkB = true
                }
                this.$forceUpdate()
            },
            getLocation(){
                this.isMap = true
                lazyAMapApiLoaderInstance.load().then(() => {
                    const geolocation = new AMap.Geolocation({
                        enableHighAccuracy: true,
                        timeout: 10000,
                        zoomToAccuracy: true,     
                        buttonPosition: 'RB'
                    })
                    geolocation.getCurrentPosition()
                    AMap.event.addListener(geolocation, 'complete', (a)=>{
                        let pos = [a.position.lng,a.position.lat]
                        this.newValue = a.formattedAddress
                        const map = new AMap.Map(`map`, {
                            resizeEnable: true,
                            center:pos
                        })
                        const marker = new AMap.Marker({
                            position: pos,
                            cursor: 'move',
                        });
                        map.setZoom(16)
                        marker.setMap(map)
                    })
                    AMap.event.addListener(geolocation, 'error', (a)=>{
                        console.log(a)
                    })
                })
            },
            sendCode(){
                let tel
                this.questions.forEach(item=>{
                    if(item.survey_question_type == 'mobile'){
                        tel = this.survey[item.valueName]
                    }
                })
                let reg = /^1[0-9]{10}$/
                if(reg.test(tel)){
                    this.$api.survey_send_text_msg({ cellphone:tel})
                    .then(res => {
                        this.$toast({ message: '发送成功~', duration: 1500 })
                        this.showTime = true
                    })
                    .catch(err => {
                        console.error(err)
                    })
                }else{
                    this.$toast({ message: '请输入正确的手机号~', duration: 1500 })
                }
                
            },
            handleTimeFinish(){
                this.codeName = '重新发送'
                this.showTime = false
            }
        },
    }
</script>

<style lang="less" scope>
    .van-icon__image{
        border-radius: 50%;
    }
    .survey{
        padding: 0 10px;
        &-name{
            padding:15px 0;
            font-size:16px;
            font-weight: bold;
        }
        .van-popup--bottom{
            z-index: 2200 !important;
        }
        .signature-box{
            background: #fff;
            padding: 0.2rem 0.32rem;
            .signature-box-title{
                margin-bottom: 5px;
                position: relative;
                span{
                    position: absolute;
                    left: -0.16rem;
                    color: #ee0a24;
                    font-size: 14px;
                }
            }
            .signature-btn{
                color: #323233;
                background-color: #fff;
                border: 1px solid #ebedf0;
                text-align: center;
                padding: 10px 0;
                border-radius: 2px;
                margin-top: 10px;
            }
        }
        #signature {
            width: auto !important;
            border: double 3px transparent;
            border-radius: 5px;
            background-image: linear-gradient(white, white),
                radial-gradient(circle at top left, #4bc5e8, #9f6274);
            background-origin: border-box;
            background-clip: content-box, border-box;
        }
        .van-cell{
            display: block;
        }
        .van-cell:not(:last-child)::after{
            border-bottom:none
        }
        .van-field__label{
            width: 100%;
        }
        .van-button--info{
            background-color: #00DE93;
            border:1px solid #00DE93;
        }
        .report-btn{
            width: 100%;
            height: 50px;
            background-color: #00DE93;
            position: fixed;
            bottom: 0;
            left: 0;
            text-align: center;
            line-height: 50px;
            color: #fff;
        }
        .survey-van-field-boeder{
            .van-field__body{
                border: 1px solid #d9d9d9;
                padding: 5px;
                border-radius: 2px;
            }
        }
        .survey-van-textarea-boeder{
            .van-cell__value{
                border: 1px solid #d9d9d9;
                padding: 5px;
                border-radius: 2px;
            }
        }
        .survey-van-radio-box{
            .van-radio-group--horizontal,.van-checkbox-group--horizontal{
                display: block;
                width: 100%;
                .survey-van-radio-boeder{
                    border: 1px solid #d9d9d9;
                    padding: 5px;
                    border-radius: 2px;
                    margin-bottom: 5px;
                }
                .survey-van-radio-boederCheck{
                    border: 1px solid #00DE93;
                    padding: 5px;
                    border-radius: 2px;
                    margin-bottom: 5px;
                }
            }
        }
        .location{
            background: #fff;
            padding: 0.2rem 0.32rem;
            &-title{
                margin-bottom: 5px;
                position: relative;
                span{
                    position: absolute;
                    left: -0.16rem;
                    color: #ee0a24;
                    font-size: 14px;
                }
            }
            &-wrap{
                display: flex;
                align-items: center;
                justify-content: center;
                border: 1px solid #d9d9d9;
                height: 100px;
            }
            &-map{
                border: 1px solid #d9d9d9;
                position: relative;
            }
            &-inp{
                border-top: 1px solid #d9d9d9;
            }
            &-address{
                padding: 5px;
                border-left: 1px solid #d9d9d9;
                border-right: 1px solid #d9d9d9;
                border-bottom: 1px solid #d9d9d9;
            }
            &-icon{
                position: absolute;
                right: 15px;
                bottom: 15px;
                z-index: 100;
            }
            .map-wrap {
                height: 150px;
            }
        }
        .survey-btn{
            min-width: 1.2rem;
            height: 0.5rem;
            font-size: 12px;
            color: #fff;
            background-color: #00DE93;
            border: 1px solid #00DE93;
            text-align: center;
            border-radius: 2px;
            display: flex;
            align-items: center;
            justify-content: center;
            .van-count-down{
                font-size: 12px;
                color: #fff;
            }
        }
    }
    .ql-editor{
        img{
            width: 100%;
        }
    }
    .float-ball{
        position: fixed;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background: #fff;
        box-shadow: 0 0 2px #999;
        .van-icon{
            font-size: 0.8rem;
        }
    }
</style>